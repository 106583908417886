import { Ref } from 'vue';
import useLoading from '@/composables/loading';
import { toast } from '@/components/ui/Toast';

import { CmsisApi } from '@/api';

interface ProjectDelete {
  isLoading: Ref<boolean>;
  onDelete: (cmsisProjectId) => Promise<void>;
}

export const useProjectDelete = (): ProjectDelete => {
  const [isLoading, remove] = useLoading(_remove);

  const onDelete = async cmsisProjectId => {
    await remove(cmsisProjectId);
  };
  return {
    isLoading,
    onDelete
  };
};

const _remove = async cmsisProjectId => {
  try {
    await CmsisApi.deleteCmSalesImpactSimulatorCmsisProjectsCmsisProjectId(
      cmsisProjectId
    );
    toast({
      title: '削除しました',
      variant: 'success'
    });
  } catch (e) {
    if (e.status === 409) {
      toast({
        title: '削除出来ません',
        message:
          '集計中のため、編集・削除ができません。しばらくしてから再度実行してください。',
        variant: 'error'
      });
    } else {
      toast({
        title: '削除失敗',
        message: e.message,
        variant: 'error'
      });
    }
  }
};
