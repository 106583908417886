<template>
  <Box>
    <slot name="activator" :on="on" />
    <div class="ui-pulldown-wrap">
      <ScrollYTransition
        appear
        scroll-y
        :reverse="reverse"
        @after-enter="afterEnter"
        @after-leave="afterLeave"
        class="ui-pulldown-transition"
      >
        <div
          class="ui-pulldown"
          :class="!displaying ? 'none-display' : ''"
          @mouseover="clearTimer"
          @mouseleave="delayLeave"
        >
          <slot :off="off" />
        </div>
      </ScrollYTransition>
    </div>
    <ClickGuard
      v-if="!useHover"
      :class="!displaying ? 'none-display' : ''"
      @click="off"
    />
  </Box>
</template>
<script>
import { defineComponent, ref, watch } from 'vue';
import ClickGuard from '@/components/ui/ClickGuard.vue';
import ScrollYTransition from '@/components/ui/ScrollYTransition.vue';
import Box from '@/components/layout/Box.vue';

export default defineComponent({
  name: 'Pulldown',
  components: { ClickGuard, ScrollYTransition, Box },

  props: {
    useHover: Boolean,
    hover: Boolean
  },
  emits: ['changeDisplaying'],

  setup: (props, { emit }) => {
    const displaying = ref(false);
    const reverse = ref(false);

    const timer = ref();
    const over = () => {
      if (!props.useHover) return;
      clearTimeout(timer.value);
      emit('changeDisplaying', true);
      displaying.value = true;
    };
    const clearTimer = () => {
      if (!props.useHover) return;
      clearTimeout(timer.value);
    };
    const leave = () => {
      if (!props.useHover) return;
      emit('changeDisplaying', false);
      displaying.value = false;
    };
    const delayLeave = () => {
      if (!props.useHover) return;
      timer.value = setTimeout(leave, 150);
    };
    watch(
      () => props.hover,
      hover => {
        if (hover) {
          over();
        } else {
          delayLeave();
        }
      }
    );

    return {
      displaying,
      reverse,
      on: () => (displaying.value = true),
      off: () => (displaying.value = false),
      clearTimer,
      delayLeave,
      afterEnter: () => (reverse.value = true),
      afterLeave: () => (reverse.value = false)
    };
  }
});
</script>
<style lang="scss">
@import '@/styles/layout';
@import '@/styles/spacing';

.ui-pulldown {
  position: absolute;
  top: $spacing-base * 2;
  z-index: $z-index-clickgurard + 1;
  background-color: var(--contrast-color);
  border-radius: 4px;
  box-shadow: var(--shadow-4);
  padding: $spacing-base * 2 0;
  width: 100%;
}

.ui-pulldown-wrap {
  position: relative;
  flex-basis: auto;
}

.none-display {
  display: none;
}
</style>
