import { computed, ref, Ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CMSIS_ROUTES } from '@/router';
import useLoading from '@/composables/loading';

import { toast } from '@/components/ui/Toast';
import { CmsisApi } from '@/api';
import {
  CmSalesImpactProductsProductTypeEnum,
  CmSalesImpactSimulatorProjectListCmsisSimulationStatusEnum,
  CmSalesImpactSimulatorProjectList,
  Period
} from '@/api/openapi';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { ColumnFilterMatchModeOptions } from 'primevue/column';

interface CmsisListReturnType {
  productTypes: Array<{ label: string; id: string }>;
  productTypeValue: Ref<CmSalesImpactProductsProductTypeEnum[]>;
  isLoading: Ref<boolean>;
  products: Ref<Array<ProductsData>>;
  intervalId: Ref<number>;
  isStopLoopRequest: Ref<boolean>;
  initialized: Ref<boolean>;
  stopLoopRequest: (number) => void;
  loopRequest: () => void;
  goToEdit: (number) => void;
  reload: (number) => void;
}
export interface DataTabelFilter {
  id: string;
  operator: string;
  constraints: { value: null; matchMode: string }[];
}
export interface DataTableReturnType {
  filters: Ref<{ [key: string]: DataTabelFilter }>;
}

export const STR_FILTER: ColumnFilterMatchModeOptions[] = [
  { label: '等しい', value: FilterMatchMode.EQUALS },
  { label: '等しくない', value: FilterMatchMode.NOT_EQUALS },
  { label: '含む', value: FilterMatchMode.CONTAINS },
  { label: '含まない', value: FilterMatchMode.NOT_CONTAINS }
];
export const NUM_FILTER: ColumnFilterMatchModeOptions[] = [
  { label: '等しい', value: FilterMatchMode.EQUALS },
  { label: '等しくない', value: FilterMatchMode.NOT_EQUALS },
  { label: '以上', value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
  { label: '以下', value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
  { label: 'より大きい', value: FilterMatchMode.GREATER_THAN },
  { label: 'より小さい', value: FilterMatchMode.LESS_THAN }
];
export const DATE_FILTER: ColumnFilterMatchModeOptions[] = [
  { label: '等しい', value: FilterMatchMode.DATE_IS },
  { label: '含まない', value: FilterMatchMode.DATE_IS_NOT },
  { label: '以前', value: FilterMatchMode.DATE_BEFORE },
  { label: 'より後', value: FilterMatchMode.DATE_AFTER }
];

export const HEADERS: {
  id: string;
  label: string;
  class: string;
  sortable?: boolean;
  sortField?: string;
  filter?: boolean;
  matchMode?: string;
  filterOptions?: ColumnFilterMatchModeOptions[];
  filterField?: string;
}[] = [
  // { id: 'isIcon', label: '', width: '12px' },
  {
    id: 'sponsorName',
    label: '企業名',
    class: 'p-sortable-column--sponsorName',
    sortable: true,
    filter: true,
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER,
    filterField: 'string'
  },
  {
    id: 'productName',
    label: '商品／ブランド名',
    class: 'p-sortable-column--name',
    sortable: true,
    filter: true,
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER,
    filterField: 'string'
  },
  {
    id: 'title',
    label: 'プロジェクト名',
    class: 'p-sortable-column--title',
    sortable: true,
    filter: true,
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER,
    filterField: 'string'
  },
  {
    id: 'areaName',
    label: '集計エリア',
    class: 'p-sortable-column--smd',
    sortable: true,
    sortField: 'areaNameSort',
    filter: true,
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER,
    filterField: 'string'
  },
  {
    id: 'duration',
    label: '参照期間',
    class: 'p-sortable-column--duration',
    sortable: true,
    // filter: true,
    matchMode: FilterMatchMode.DATE_IS,
    filterOptions: DATE_FILTER,
    filterField: 'date'
  },
  {
    id: 'roas',
    label: '最大ROAS',
    class: 'p-sortable-column--sm end',
    // sortable: true,
    // filter: true,
    matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
    filterOptions: NUM_FILTER,
    filterField: 'number'
  },
  {
    id: 'cmEffect',
    label: '購入増加額',
    class: 'p-sortable-column--sm end',
    // sortable: true,
    // filter: true,
    matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
    filterOptions: NUM_FILTER,
    filterField: 'number'
  },
  {
    id: 'maxRoasCondition',
    label: '最大ROAS条件',
    class: 'p-sortable-column--sh end',
    // sortable: true,
    // filter: true,
    matchMode: FilterMatchMode.CONTAINS,
    filterOptions: STR_FILTER,
    filterField: 'string'
  },
  {
    id: 'lastUpdateDateTimeAt',
    label: '最終更新日',
    class: 'p-sortable-column--xsh center',
    sortable: true,
    filter: true,
    matchMode: FilterMatchMode.DATE_IS,
    filterOptions: DATE_FILTER,
    filterField: 'date'
  },
  { id: 'actions', label: '', class: 'p-sortable-column--sh last-colum' }
];

const AREA_ORDERS = {
  '関東・関西・中京': 1,
  '関東・関西': 2,
  '関東・中京': 3,
  '関西・中京': 4,
  関東: 5,
  関西: 6,
  中京: 7
};

const PRODUCT_TYPES = [
  { label: '自社', id: CmSalesImpactProductsProductTypeEnum.OwnCompany },
  {
    label: '競合他社',
    id: CmSalesImpactProductsProductTypeEnum.CompetitiveCompany
  }
];

// Errorアイコンの表示
export const getIconName = (
  value: CmSalesImpactSimulatorProjectListCmsisSimulationStatusEnum
): string => {
  switch (value) {
    case CmSalesImpactSimulatorProjectListCmsisSimulationStatusEnum.Error:
      return 'ban';
    case CmSalesImpactSimulatorProjectListCmsisSimulationStatusEnum.InvalidModelError:
      return 'exclamation';
    case CmSalesImpactSimulatorProjectListCmsisSimulationStatusEnum.NoAdAreaError:
      return 'exclamation';

    default:
      return '';
  }
};

// Errorメッセージの表示
export const gettoolTipMessage = (
  value: CmSalesImpactSimulatorProjectListCmsisSimulationStatusEnum
): string => {
  switch (value) {
    case CmSalesImpactSimulatorProjectListCmsisSimulationStatusEnum.Error:
      return 'シミュレーションに失敗しました。編集画面から再度、実行してください。';
    case CmSalesImpactSimulatorProjectListCmsisSimulationStatusEnum.InvalidModelError:
      return '購入増加額がマイナスの週が多く含まれているためシミュレーションに失敗しました。詳細は編集画面を確認してください。';
    case CmSalesImpactSimulatorProjectListCmsisSimulationStatusEnum.NoAdAreaError:
      return '出稿がまったく無いエリアを含んでいるためシミュレーションに失敗しました。';
    default:
      return '';
  }
};

import { format } from 'date-fns';
import { DATE_FORMAT } from '@/common/format';
export interface ProductsData extends CmSalesImpactSimulatorProjectList {
  lastUpdateDateTimeAt: Date;
  duration: string;
}

export const formatDurationLabel = (periods: Period[]): string => {
  if (periods.length === 0) {
    return '';
  }
  const startDates = periods.map(v => new Date(v.startDate).getTime());
  const endDates = periods.map(v => new Date(v.endDate).getTime());
  const minDate = new Date(Math.min.apply(null, startDates));
  const maxDate = new Date(Math.max.apply(null, endDates));
  return `${format(minDate, DATE_FORMAT)} ~ ${format(maxDate, DATE_FORMAT)}`;
};

export const useProjectList = (): CmsisListReturnType => {
  const route = useRoute();
  const router = useRouter();
  const { params } = route;
  const companyId = Number(params.companyId);

  const productTypeValue = ref([
    CmSalesImpactProductsProductTypeEnum.OwnCompany,
    CmSalesImpactProductsProductTypeEnum.CompetitiveCompany
  ]);
  const products = ref([] as ProductsData[]);
  const origProducts = ref([] as CmSalesImpactSimulatorProjectList[]); //filter用

  const _fetch = async (
    id: number
  ): Promise<CmSalesImpactSimulatorProjectList[]> => {
    // 商品一覧の取得
    try {
      const { data } = await CmsisApi.getCmSalesImpactSimulatorCmsisProjects(
        id
      );

      return data as CmSalesImpactSimulatorProjectList[];
    } catch (e) {
      toast({
        title: '失敗',
        variant: 'error'
      });
      return [] as CmSalesImpactSimulatorProjectList[];
    } finally {
      // ループ処理が画面遷移後に実行されていた場合停止
      setTimeout(async () => {
        if (isStopLoopRequest.value) stopLoopRequest(intervalId.value);
      }, 1000);
    }
  };

  const [isLoading, fetch] = useLoading(_fetch);

  // 一覧の取得
  const get = async (id: number) => {
    const raw = await fetch(id);
    const list = raw.map(v => ({
      ...v,
      areaNameSort: AREA_ORDERS[v.areaName],
      duration: formatDurationLabel(v.periods),
      lastUpdateDateTimeAt: new Date(v.lastUpdateDateTime)
    }));
    products.value = filter({
      products: list,
      productType: productTypeValue.value
    });
    origProducts.value = [...list];
  };

  const intervalId = ref();
  const isStopLoopRequest = ref(false);

  const searchingList = computed(() => {
    return products.value.filter(
      v =>
        v.cmsisSimulationStatus ===
          CmSalesImpactSimulatorProjectListCmsisSimulationStatusEnum.Pending ||
        v.cmsisSimulationStatus ===
          CmSalesImpactSimulatorProjectListCmsisSimulationStatusEnum.InProgress
    );
  });

  const stopLoopRequest = id => {
    clearInterval(id);
  };

  const loopRequest = async () => {
    intervalId.value = setTimeout(async () => {
      if (!isLoading.value && searchingList.value.length > 0) {
        await get(companyId);
      }
      loopRequest();
    }, 10000);
  };

  const initialized = ref(false);

  (async () => {
    await get(companyId);
    loopRequest();
    initialized.value = true;
  })();

  // 設定保存後のリロード
  const reload = async () => {
    await get(companyId);
  };
  // CheckBoxfilter連動
  watch(productTypeValue, value => {
    products.value = filter({
      products: origProducts.value,
      productType: value
    });
  });

  const goToEdit = async (projectId: number) => {
    await router.push({
      name: CMSIS_ROUTES.edit,
      params: { projectId }
    });
  };

  return {
    productTypes: PRODUCT_TYPES,
    productTypeValue,
    isLoading,
    products,
    intervalId,
    isStopLoopRequest,
    initialized,
    stopLoopRequest,
    loopRequest,
    goToEdit,
    reload
  };
};

export const useDataTable = (): DataTableReturnType => {
  const filters = ref();
  const tmp = HEADERS.map(v => {
    if (!v.filter) return;
    return {
      id: v.id,
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: v.matchMode }]
    };
  }).filter(Boolean) as DataTabelFilter[];
  filters.value = tmp.reduce((p, c) => {
    return { ...p, [c.id]: c };
  }, {});

  return {
    filters
  };
};

const filter = ({ products, productType }) => {
  return products.filter(product => productType.includes(product.productType));
};
